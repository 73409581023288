import { ApolloClient } from "apollo-client"
import fetch from "isomorphic-fetch"
import { createHttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"

const httpLink = createHttpLink({
  uri: "https://api.mercedes-erleben.de/",
  // uri: "http://127.0.0.1:4000/",
  fetch,
})

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})
