import React from 'react'
import { ApolloProvider } from 'react-apollo'

import { client } from "./src/utils/client"
import { AppProvider } from './src/contexts/AppContext'

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <AppProvider>{element}</AppProvider>
  </ApolloProvider>
)
