import React, { useState } from 'react'
import PropTypes from 'prop-types'
import momentBusinessDays from 'moment-business-days'

const AppContext = React.createContext()

const AppConsumer = AppContext.Consumer

function AppProvider(props) {
  const [car, setCar] = useState('A-Klasse')

  const [salutation, setSalutation] = useState('')
  const [title, setTitle] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [street, setStreet] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [email, setEmail] = useState('')

  const [brand, setBrand] = useState('')
  const [modell, setModell] = useState('')
  const [vehicleProcurement, setVehicleProcurement] = useState(null)
  const [procurementMethod, setProcurementMethod] = useState(null)

  const [dateDay, setDateDay] = useState(momentBusinessDays().businessAdd(2).toDate())
  const [dateTime, setDateTime] = useState('')

  const contextValue = {
    car,
    setCar,
    salutation,
    setSalutation,
    title,
    setTitle,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    street,
    setStreet,
    zipCode,
    setZipCode,
    city,
    setCity,
    phoneNumber,
    setPhoneNumber,
    mobileNumber,
    setMobileNumber,
    email,
    setEmail,
    brand,
    setBrand,
    modell,
    setModell,
    vehicleProcurement,
    setVehicleProcurement,
    procurementMethod,
    setProcurementMethod,
    dateDay,
    setDateDay,
    dateTime,
    setDateTime,
  }

  return (
    <AppContext.Provider value={contextValue}>
      {props.children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

export { AppContext, AppProvider, AppConsumer }
